import React from 'react';
import { checkConsent, postAcceptConsent } from '../../apis';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

export default ({ consentName=false }) => {
  const [content, setContent] = React.useState();

  React.useEffect(() => {
    check();
  }, []);

  const check = async () => {
    try {
      const res = await checkConsent(consentName);
      if (!res.is_accepted) {
        setContent(res.consent);
      }
    } catch (e) {}
  }

  const accept = async () => {
    await postAcceptConsent(consentName ? {consent_name: consentName} : '');
    setContent(null);
  }

  return (
    <Dialog
      fullScreen
      open={!!content}
      onClose={accept}
      scroll="body"
    >
      <DialogTitle>กรุณายอมรับเงื่อนไขการให้บริการ</DialogTitle>
      <DialogContent>
        <DialogContentText
          tabIndex={-1}
          style={{ fontSize: '0.9em', color: 'black' }}
        >
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => window.location.href = 'me.moronline://exit'} color="primary">
          ยกเลิก
        </Button>
        <Button onClick={accept} color="primary">
          ยอมรับเงื่อนไข
        </Button>
      </DialogActions>
    </Dialog>
  )
}