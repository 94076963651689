import React from 'react';
import ChannelList from '../../../components/Chat/ChannelList';
import Consent from '../../../components/Assessment/Consent';

export default (prop) => {
  return (
    <>
      <Consent consentName={process.env.GATSBY_CONSENT_DOCTOR}/>
      <ChannelList isStaff {...prop} />
    </>
  )
}
